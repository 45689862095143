.fs_root {
  justify-content: center;
  //padding-bottom: 2rem;

  .fs_item {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    .fs_img_link {
      display: flex;
      justify-content: center;
    }

    .fs_item_img {
      filter: drop-shadow(-3px 6px 6px #0003);
      max-width: 100%;
      transition: filter .3s;

      &:hover {
        filter: none;
      }
    }
  }

  .fs_item_bottom {
    justify-content: flex-end!important;
  }

  .fs_item_name {
    font-size: 1rem;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }
}

.facade_sizes {
  text-align: center;

  table {
    margin: 1rem auto;

    th, td {
      border: 1px solid $body-color;
      font-size: .75rem;
      text-align: center;
      padding: .22rem;
    }
  }
}

.facade_type {
  text-align: center;

  div {
    margin: .5rem auto;
  }
}

.facade_notes {
  font-size: .7rem;
  font-style: italic;
  text-align: center;
  margin-top: -.7rem;
  line-height: 1.1;
}

.fs_legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.profile_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  margin: 1px;

  p {
    margin: .3rem;
    line-height: 1.2;
  }

  img {
    margin: 1rem;
  }
}

.profile_about {
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1000px) {
    display: block;
  }
}

.about_system_img {
  float: left;
  margin: 1rem;
}

.decor_item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin:2rem 1rem;
  img {
    max-width:48px;
  }
}