.btn_block {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  img {
    margin: 1rem 2rem;
  }
}

.wbtn {
  margin: 1rem;
  text-transform: uppercase;
  background: #ffffff00;
  transition: all .3s;

  &:hover {
    background: #ffffff22;
    border-color: transparentize($btn_border_color, .3);
    color: transparentize($body-color, .3);
  }
}