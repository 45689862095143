.std_table {
  margin: 1rem auto;
  width: 100%;

  tr {
    border-top: $body-color 1px solid;
    border-bottom: $body-color 1px solid;
  }

  th, td {
    margin:0;
    border-left: $body-color 1px solid;
    border-right: $body-color 1px solid;
    border-collapse: collapse;
    text-align: center;
    padding: 2px 4px;
  }
}

.about_table {
  margin: .5rem;
  width: 97%;
}

@media screen and (max-width: 750px) {
  .table-box, .table_scroll {
    overflow-x: scroll;
  }
}
