@import '../styles/vars';
@import 'button';
@import 'radio';
@import 'select';
@import 'text_input';
@import 'textarea';

.law {
  font-size: .6rem;
  font-style: italic;
  line-height: 1;
  margin-top: 5px;
}

label {
  width: 100%;
  color: lighten($body_color, 20%);
  .required {
    color: $accent_color;
  }
}

.form-control,
input, select, radio, textarea {
  background: $body-bg;
  border: 1px solid $accent_color;
  width: 100%;
  color: $body_color!important;

  &.required {
    border: 1px solid darken($accent_color, 20%);
  }

  &.error {
    border-color: #ff3232 !important;
  }

  .required {
    color: $accent_color;
  }

}
