.news_list {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: inline-block;
    margin-bottom: 2rem;
    max-width: 100%;
    height:auto;
  }

  h2 {
    text-align: left;
    span {
      font-weight: 300;
    }
  }
}