.furniture_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.furniture_legend {

  ul {
    counter-reset: fl;
    list-style: none;
    column-count: 3;
    column-width: 250px;
    margin: 1rem 0;

    li {
      position: relative;
      line-height: 1;
      margin: .4rem 0 .4rem 2rem;
      break-inside: avoid;
      text-indent: -2.6rem;

      &:before {
        counter-increment: fl;
        content: counter(fl);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-indent: 0;
        line-height: 1;
        position: relative;
        padding: 4px;
        width: 1.7rem;
        top: 0;
        border-radius: 50%;
        background: $body-color;
        color: $body-bg;
        border: 2px solid $accent_color;
      }
    }
  }
}