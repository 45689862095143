h1, .h1 {
  color: $h1_color;
  margin-top: 1.5rem;
  text-transform: uppercase;
  //font-size: 1.125rem;
  text-align: center;
}

#doc_1 h1{
  font-size: 1.5rem;
}

h2, .h2 {
  color: $h1_color;
  text-transform: uppercase;
  //font-size: .875rem;
  margin-top: 1.5rem;
  padding-bottom: 0;
  text-align: center;
}

h3, .h3 {
  color: $h1_color;
  font-size: 1.3rem;
  padding-bottom: 0rem;
  display: block;
  text-align: center;

  &.all_facades{
    font-size: 1.3rem;
    //font-size: 1rem;
    text-transform: uppercase;
  }
}

h4, .h4 {
  margin:1rem 1rem 0 1rem;
  font-size: 1.2rem;
  text-align: center;
}

p {
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.no_decor {
  text-decoration: none;
}

.img-thumbnail {
  background: none;
}

.no_border {
  border: none !important;
}

.pad0 {
  padding: 0 !important;
}

.mb0 {
  margin-bottom: 0;
}

.pb0 {
  padding-bottom: 0;
}

.display-block {
  display: block;
}

.align-center {
  text-align: center !important;
  text-indent: 0!important;
}

.align-left {
  text-align: left !important;
}

.i {
  font-style: italic;
}