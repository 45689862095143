@import "../styles/vars";

$btn_border_color: $accent_color !default;
$btn_bg_color: #685744 !default;
//$bg: darken($btn_border_color,30%) !default;

@mixin waves-transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin waves-transform($string) {
  -webkit-transform: $string;
  -moz-transform: $string;
  -ms-transform: $string;
  -o-transform: $string;
  transform: $string;
}

@mixin waves-box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    $gradient: rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .5) 60%, rgba(255, 255, 255, 0) 70%;
    background: -webkit-radial-gradient($gradient);
    background: -o-radial-gradient($gradient);
    background: -moz-radial-gradient($gradient);
    background: radial-gradient($gradient);
    @include waves-transition(all 0.5s ease-out);
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    @include waves-transform(scale(0) translate(0, 0));
    pointer-events: none;
  }

  &.waves-light .waves-ripple {
    background: transparentize($btn_bg_color, 0.4);
    $gradient: transparentize($btn_bg_color, 0.2) 0, transparentize($btn_bg_color, .3) 40%, transparentize($btn_bg_color, .4) 50%, transparentize($btn_bg_color, .5) 60%, transparentize($btn_bg_color, 0) 70%;
    background: -webkit-radial-gradient($gradient);
    background: -o-radial-gradient($gradient);
    background: -moz-radial-gradient($gradient);
    background: radial-gradient($gradient);
  }

  &.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, 0.2);
  }

  &.waves-classic.waves-light .waves-ripple {
    background: transparentize($btn_bg_color, 0.4);
  }
}

.waves-notransition {
  @include waves-transition(none #{"!important"});
}

.waves-button,
.waves-circle {
  @include waves-transform(translateZ(0));
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
  //white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid $btn_border_color;
  outline: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}

.waves-button {
  padding: 0.85em 1.1em;
  border: 1px solid $btn_border_color;
}

.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;

  &.waves-button {
    padding: 0;
  }

  .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}

.waves-float {
  -webkit-mask-image: none;
  @include waves-box-shadow(0px 1px 1.5px 1px rgba(0, 0, 0, 0.12));
  @include waves-transition(all 300ms);

  &:active {
    @include waves-box-shadow(0px 8px 20px 1px transparentize($btn_border_color, .2));
  }
}

.waves-block {
  display: block;
}
