.owl-carousel {
  margin-top: 1rem;

  .owl-item {
    position: relative;
    img {
  //    width: auto;
      margin: auto;
    }

    .img {
      position: relative;
    }

    .zagol{
      position:absolute;
      bottom:0;
      width:100%;
      z-index: 10;
      background:#0008;
    }
  }

  a {
    text-align: center;
  }
}