.btn-up {
  position: fixed; /* фиксированная позиция */
  background-color: rgba($accent_color,10%); /* цвет заднего фона */
  right: 20px; /* расстояние от правого края */
  bottom: 20px; /* расстояние от нижнего края */
  border-radius: 22px; /* скругление верхнего левого угла */
  border: 1px solid $accent_color;
  cursor: pointer; /* форма курсора */
  display: flex; /* не отображать элемент */
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  width: 44px;
  height: 44px;
  opacity: 1;
}

.btn-up::before {
  content: "";
  width: 40px;
  height: 40px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' stroke='%23882b34' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10 17V4M3 10l7-7 7 7'/%3E%3C/g%3E%3C/svg%3E");
}

.btn-up_hide {
  display: none;
}

.btn-up_hiding {
  opacity: 0;
}

@media (hover: hover) and (pointer: fine) {
  .btn-up:hover {
    border-color: lighten($accent_color,20%); /* цвет заднего фона при наведении */
  }
}