.contacts_schedule {
  margin-left: 3rem;
  margin-bottom: 1rem;

  td {
    padding: .5rem;
    border: 1px solid $body-color;
    border-collapse:collapse;
  }
}

.manager_root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding-bottom: 4rem;
}

.manager {
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 1rem;
  position: relative;
  padding-bottom: 4rem;

  .manager_img {
    margin: 0 1rem;

    .manager_cnt {
      border-bottom: 1px dashed #fff;
      padding-bottom: .5rem;
    }
  }

  .manager_text {
    margin-bottom: 1rem;

    .h3 {
      text-align: left;
      text-transform: uppercase;
    }
  }
}