.wide_gallery {
  display: flex;
  flex-direction: column;
  align-items: center;

  a{
    display:block ;
    margin: 1rem;
    max-width: 800px;
  }
}

.store_logo{
  display:flex;
  margin-bottom:1rem;
  justify-content: center;
}

.pp_image {
  width: -moz-fit-content; width:fit-content;
}