.fasade_type {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //text-shadow: 1px 1px 1px #000a;
}

.facade_price {
  position: absolute;
  right: 4px;
  bottom: 4px;
  background: rgba(20, 20, 20, 0.8);
  //text-shadow: 1px 1px 1px #000a;
  color: $accent_color;
  border-radius: 10px 0 0 0;
  padding: 4px 10px;
}

//.view_system {
//  color: $accent_color;
//  font-size: .8rem;
//  display: block;
//  text-align: center;
//  margin-bottom: 2.5rem !important;
//  text-transform: uppercase;
//  position: relative;
//  margin: 0;
//  line-height: .8;
//  text-decoration: none;
//  text-indent: 0 !important;
//}

.lm_img {
  display: inline-flex;
  justify-content: center;
  position: relative;
}

//--------------------------------------------------------
.photo {
  background: $light_block;
  position: relative;

  .lm_series_title {
    font-size: 1.3rem;
    position: absolute;
    text-transform: capitalize;
    left: -2.15rem;
    top: 4px;
    color: $h2_color;
    text-align: left;
    text-shadow: 1px 1px 2px $light_block;
    z-index: 10;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, .5);
    //font-weight: 900;
  }

  img {
    object-fit: cover;
    box-shadow: 5px 15px 15px 0 rgba(0, 0, 0, .5);
    display: block;
    transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover {
    img {
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .1);
    }

    .glow {
      transform: rotate(45deg) translate(450%, 0);
      transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    img, .glow-wrap, .facade_price, {
      transform: translate(3px, 3px);
    }

    h3 {
      transform: translate(0, -10px);
    }
  }

  .glow-wrap {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 0;
  }

  .glow {
    display: block;
    position: absolute;
    width: 40%;
    height: 200%;
    background: rgba(255, 255, 255, .2);
    top: 0;
    filter: blur(5px);
    transform: rotate(45deg) translate(-450%, 0);
    transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.system_type {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(5%, -57%);
  text-transform: uppercase;
  //text-shadow: 1px 1px 2px #000;
  margin: 0;
  line-height: 1;
}

.fs_chapter {
  position: relative;

  .fs_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    
    a {
      display: inline-block;
      width: 250px;
    }
  }

  margin-bottom: 2rem;
}

//--------------------------------------------------------
.facades_chapter_price {
  position: absolute;
  transition: all .5s ease;
  width: 100%;
  height: 100%;
  opacity: 0;
  bottom: 100%;
  padding: 4px;

  nobr {
    display: block;
    line-height: 1.2;
    width: 100%;
    height: 100%;
    background: #882b34;
    font-size: 1rem;
    font-weight: normal;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-indent: 0;
  }

  a {
    text-align: center;
    color: $body-color;
    border: solid 1px $accent_color;
    z-index: 10;
  }

}

.series_name {
  text-transform: uppercase;
  //font-family: Sofia;
  font-weight: normal;
  font-size: 1rem;
  bottom: -8px;
  position: absolute;
  width: 100%; //227px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;

  transition: all 0.5s ease;

  h3 {
    background: $body-bg;
    width: 100%;
    font-weight: 400;
    font-size: .8rem;
  }

  flex-grow: 1;
}

.facades_image {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  padding:.5rem;
  filter: drop-shadow(-2px 2px 3px #0009);

  //img{
  //  width:250px;
  //}
}

.facades_chapter {
  position: relative;

  &:hover {
    .facades_chapter_price {
      opacity: 1;
      transition: all 0.5s ease;
      bottom: 0;
    }

    .series_name {
      transition: all 0.5s ease;
      bottom: -56px;
      opacity: 0;
    }
  }
}

.facades_chapter_overlay {
  display: block;
  background: rgba(0, 0, 0, .3);
}

.facades_chapter_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.facades_chapter_title {
  position: absolute;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  text-indent: 0;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all .5s ease;
  color: #000;
  //text-shadow: 0 0 3px #fff;
  background: #fff7;
  padding: 0 5px;

}

.facades_chapter:hover {
  .facades_chapter_title {
    transform: translate(-50%, -3rem);
  }
}

.all_facades_body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  .all_facades_item {
    text-align: center;
    margin: .5rem 1rem;

    .all_facades_ttl {
      margin-bottom: .5rem;
    }

    img {
      @extend .img_shadowed;
    }
  }
}
