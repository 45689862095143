.flipper_wrapper {
  position: relative;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.flipper_face {
  position: absolute;
  background: $body-bg;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 0.7rem;
  text-align: center;

  img {
    width: 40%;
  }
}

.flipper_front,
.flipper_back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// ============== mainpage ==============
.mainpage_causes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  width: 1000px;
  margin: auto;

  @media screen and (max-width: 1300px){
    width: 95%;
  }

  .flipper_wrapper {
    max-width: 170px;
    margin: 0 20px 40px 20px;

    @media screen and (max-width: 1200px){
      margin: 0 10px 20px 10px;
    }

    .flipper_card {
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      .flipper_front,
      .flipper_back {
        border: $accent_color 1px solid;
        text-align: center;
        padding: 1.1rem .6rem;
      }

      .flipper_back {
        padding: 1rem;
      }

      .cause_name, .cause_description {
        text-align: center;
        font-size: .8rem;
        line-height: 1.1;
      }

      .cause_name {
        flex-grow: 2;
      }

      .cause_description {
        color: $accent_color;
      }
    }
  }
}
