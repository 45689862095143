.nav_wrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: $body-bg;

  @media (max-width: 991px) {
    position: relative;
  }
}

.top_nav {
  margin-top: 1rem;
  margin-bottom: .5rem;
  z-index: 99999;
  text-transform: uppercase;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}

nav {
  padding-top: 15px !important;
  font-size: 16px
}

.navbar_div {
  margin-left: 0 !important;

}

.navbar-expand-lg {
  justify-content: space-between;
  margin-top: var(--bs-gutter-y);
  align-items: flex-start;

  .navbar-nav {

    a {
      text-decoration: none;
    }

    .nav-link {
      text-transform: uppercase;
      position: relative;
      font-size: .87em;
      padding-right: 1rem;
      padding-left: 1rem;


      &.active {
        border-top: 1px solid $accent_color;
        //&:before {
        //  content: " ";
        //  display: block;
        //  position: absolute;
        //  width: 32px;
        //  height: 8px;
        //  bottom: -4px;
        //  left: 1.2rem;
        //  background: url("/assets/images/common/menu/menu_active.png");
        //  z-index: 0;
        //}
      }

      @include media-breakpoint-down(xl) {
        font-size: .9em;
        padding-left: .5rem;
        padding-right: .5rem;
      }

    }
  }
}

.yamm .dropdown.yamm-fw .dropdown-menu, .yamm .dropup.yamm-fw .dropdown-menu {
  margin-top: 1.5rem;
}

.nav_sub_3_list {
  margin-bottom: 1rem;
  padding-left: 0;
  font-size: .8rem;
}

.navbar_contacts {
  position: absolute;
  right: 0;
  width: 320px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding-right: 2rem;
  text-transform: none !important;

  @include media-breakpoint-down(sm) {
    position: relative;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .navbar_phone {
    padding-top: 0;
    font-size: 1rem;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 1rem;
    }

    @media(max-width: 1280px) {
      flex-direction: column;
      align-items: flex-end;
      a {
        margin-left: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.1em;
    }
  }

}

.nav_sub_1_ttl {
}

.nav_sub_1_ttl, .nav_sub_2_ttl {
  font-weight: bolder;
}

.nav_sub_2 {
  .h6 {
    color: $body-color;
    //font-weight: 500;
    display: block;

    &:hover {
      color: $active_color;
    }
  }
}

.nav_sub_3_list {
  font-size: .8rem;
  padding-left: 1rem;
  list-style: outside none;
  font-weight: 500;

  li {
    margin: 0;
    text-indent: -1rem;

    &:before {
      content: '—';
      color: $body-color;
      font-weight: bold;
    }
  }
}

.nav_add {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.dropdown-toggle {
  &:hover {
    &::after {
      color: $body-color;
    }
  }
}