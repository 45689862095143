#search_btn {
  position: relative;
}

#search_input {
  position: absolute;
  display: none;
  right: 30px;
  top: 0;
  width: 300px;
}

//.autocomplete-w1 {
//  background: $light_block;
//  position: absolute;
//  top: 0px;
//  left: 0px;
//  margin: 1px 0 0 0;
//}
//
//.autocomplete {
//  border: 1px solid #999;
//  background: $light_block;
//  cursor: default;
//  text-align: left;
//  max-height: 350px;
//  overflow: auto;
//  overflow-x: hidden;
//}
//
//.autocomplete .selected {
//  background: $body-bg;
//}
//
//.autocomplete div {
//  padding: 2px 5px;
//  white-space: nowrap;
//  overflow: hidden;
//}
//
//.autocomplete strong {
//  font-weight: normal;
//  //color: #39f;
//}
