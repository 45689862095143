.main_image {
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;

  .main_image_div {
    position: relative;
    align-self: flex-start;
    width: calc(100% - 116px);
    z-index: 10;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .mainpage_adv {
      position: absolute;
      left: 0;
      top: 50%;
      //width: 510px;
      font-size: 1.2rem;
      color: $h2_color;
      background: #fffa;
      display: flex;
      padding: 1rem;
      transform: translateY(-50%);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border: 1px solid $accent_color;
      border-left: none;


      @include media-breakpoint-down(sm) {
        font-size: .8rem;
      }

      .wbtn {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }

  picture {
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height:auto;
  }

}

.mp-dark-block {
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}

.mp-empty-space {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hot_new_wrapper {
  display: flex;
  justify-content: center;

  .hot_new {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    margin-bottom: 1rem;

    img {
      display: block;
      max-width: 100%;
      height:auto;
      border: 1px solid $accent_color;
      margin: auto;
    }
  }
}

.mp_intro p {
  margin: 0;
}