:root {
  --bs-body-font-size: 18px;
}

$font-family-sans-serif: GothamPro, Roboto, "Segoe UI", Arial, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;

//$font-size-base: 0.875rem; // Assumes the browser default, typically `16px`

// my variables
$accent_color: #882b34;//#8c4200;

// bootstrap variables overwrite
$body-bg: #e6e6e6;
$dark-color:#212121;
$body-color: #444240;//#424242;
$active_color: $accent_color;//#212121;
$light_block: #eeeeee;

$border-color: $accent_color;
$thumbnail-border-color: $accent_color;

$enable-rounded: false;
$enable-shadows: true;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 1.2;
$h2-font-size: $font-size-base * 1.1;
$h3-font-size: $font-size-base * 1;
//$h4-font-size: $font-size-base * 1.5 !default;
//$h5-font-size: $font-size-base * 1.25 !default;
//$h6-font-size: $font-size-base !default;

// -------- form/modals --------
$modal-content-border-color: $accent_color;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;
$modal-backdrop-bg: $body-bg;
$modal-backdrop-opacity: .2;
$modal-transition: transform .6s ease-out;
$modal-content-bg: $body-bg;
$modal-footer-border-color: $accent_color;
$modal-header-border-color: $accent_color;
$btn-close-color: lighten($body_color, 50%);
$form-label-color: lighten($body_color, 50%);

// -------- toasts --------
//$toast-max-width:                   350px;
//$toast-padding-x:                   .75rem;
//$toast-padding-y:                   .5rem;
//$toast-font-size:                   .875rem;
$toast-color: $body-color;
$toast-background-color: $body-bg;
//$toast-border-width:                1px;
$toast-border-color: $accent_color;
$toast-border-radius: 0;
//$toast-box-shadow:                  $box-shadow;
//$toast-spacing:                     $container-padding-x;

$toast-header-color: lighten($body-color, 20%);
$toast-header-background-color: $light_block;
$toast-header-border-color: null;


// -------- other --------
$h1_color: $accent_color;
$h2_color: #212121;

$link-color: $body-color;
$link-hover-color: lighten($link-color, 20%);

$navbar-nav-link-padding-x: 2rem;
$navbar-dark-color: $body-color;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-padding-y: 1rem;
$navbar-dark-color:                 $body-color;
$navbar-dark-hover-color:           $active_color;
$navbar-dark-active-color:          $active_color;

$dropdown-bg: $body-bg !important;
$dropdown-border-color: $accent_color;

$pagination-color:                  $body-color;
$pagination-bg:                     $body-bg;
$pagination-border-radius:          0;
$pagination-border-width:           1px;
$pagination-border-color:           $accent_color;

$pagination-hover-color:            $body-bg;
$pagination-hover-bg:               $accent_color;
$pagination-hover-border-color:     $accent_color;

$pagination-active-color:           $body-bg;
$pagination-active-bg:              $accent_color;
