
.xl_table {
  margin: 1rem auto;
  border-top: 1px solid $body-color;

  .xl_link {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $body-color;
    justify-content: space-between;

    .xl_name {
      flex-grow: 2;
    }
    th, td {
      border: none;
      text-align: left;
      padding: 2px 4px;
    }
  }

  .xl_img {
    display: block;
    margin-right: .5rem;
  }

  .xl_btn {
    margin: .5rem;
    padding: .5rem
  }
}