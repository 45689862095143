@import '../styles/vars';
@import '../form/styles';
@import './send_letter/styles';

.modal {
  z-index: 999999;

  p{
    text-indent: 0;
  }
}

.bClose {
  color: $accent_color;
}

.modal-header {
  position:relative;
  text-transform: uppercase;
  justify-content: center;

  h5.modal-title {
    text-align: center;
  }

  .btn-close {
    position: absolute;
    right: 16px;
    top: 23px
  }
}

.modal_about {
  .modal-dialog {
    max-width: 80%;

    @media all and (max-width: 580px) {
      max-width: 100%;
    }
  }
}

#order_check {
  .modal-dialog {
    max-width: 300px;
    input{
      font-size: .9rem;
    }
  }
}