
#cookie_notification{
  display: none;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  width: 900px;
  max-width: 90%;
  transform: translateX(-50%);
  padding: 1.5rem;
  background-color: $body-bg;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
}

#cookie_notification p{
  text-indent: 0;
  margin: 0;
  font-size: 0.7rem;
  text-align: left;
  //color: #e6e6e6;
}


@media (min-width: 576px){
  #cookie_notification.show{
    display: flex;
  }
  .cookie_accept{
    margin: 0 0 0 1.5rem;
    width: 12.5rem;
  }
}

@media (max-width: 575px){
  #cookie_notification.show{
    display: block;
    text-align: left;
  }
  .cookie_accept{
    margin: 10px 0 0 0;
  }
}

//.cookie_warning {
//  position: fixed;
//  bottom: 0;
//  left: 0;
//  display: none;
//  justify-content: center;
//  width: 100%;
//  padding: 30px;
//  box-sizing: border-box;
//  background-color: yellow;
//}
//
//.warning__active {
//  display: flex;
//}
//
//.warning__text {
//  margin-right: 30px;
//}
//
//.warning__read {
//  display: block;
//  margin-left: 15px;
//}