.right_icons {
  width: 120px;
  //background: $light_block;
  position: fixed;
  top:126px;
  right: 0;
  z-index: 10;

  //@include media-breakpoint-down(lg) {
  //  width: 80px;
  //}

  @media (max-width: 1000px) {
    display: none;
  }

  //@include media-breakpoint-down(sm) {
  //  display: none;
  //}
}

.right_icons_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  a {
    text-decoration: none;
    transition: color .3s;
    padding: 1rem;
    font-size: 1.2rem;
    opacity: .6;
  }

  .check_order {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column !important;
    font-size: .7rem;
    line-height: 1;
    //color: $accent_color;
    border: 1px solid $accent_color;
    text-align: center;
    padding:8px;
    width:84px;

    .check_order_img {
      width: 24px !important;
      margin-bottom: 5px;
    }
  }
}


.right_icons_index {
  position: fixed;
  top: 100px;
  right: 0;
  min-width: 80px;
  @include media-breakpoint-down(lg) {
    min-width: 60px;
  }
}


//:not(.main_image) .right_icons {
//  justify-self: flex-end;
//  width: 80px;
//  //position: relative;
//  //right: 0;
//}