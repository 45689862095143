#door_view {
  overflow: auto !important;
  background: none;
}

.doors_ttl {
  text-align: center;
  padding-bottom: 20px;
}

.doors_ttl,
.doors_info {
  color: #b5b5b5;
  //color: $body-color;
}

.doors_wrap {
  justify-content: center;

  .col-sm-12 {
    text-align: center;
  }

  img {
    max-height: 80vh;
  }
}
