.video_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  .video_button {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      background: url("/assets/images/common/youtube.png") center center no-repeat;
      background-size: 20%;
      content: ' ';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      opacity: 1;
      transition: opacity .3s;
      cursor: zoom-in;
    }

    &:hover{
      :after {
        opacity: .5;
      }
    }
  }

  .h3 {
    text-align: center;
  }
}
