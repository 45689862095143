.fancybox__container{
  z-index: 99999;
}

.fancybox-content {
  background-color: rgba($body_bg, .7);
}

#furniture_carousel {
  position: relative;
}

.carousel__slide {
  border: 1px solid $accent_color;
  margin: .7rem;
  padding: 2px;
  width: auto;
  display: flex;
  flex-direction: column;

  .addons{
    width: 194px;
  }
}

.carousel__caption {
  position:absolute;
  z-index: 1;
  bottom:0;
  width:100%;
  background-color: #000b;
  text-decoration: none;
  text-align: center;
  color:$body-color;
  font-size: .75rem;
  line-height: 1.1;
  padding:.2rem;
}

.carousel__button {
  background: $body-bg;
  border: 1px solid $accent_color;
  width: 32px;
  height: 32px;

  &.is-next {
    right: 0;
  }

  &.is-prev {
    left: 0;
  }

  &.is-prev, &.is-next {
    transform: translateY(-76%);
  }
}

.carousel__dots {
  position: static;
}
