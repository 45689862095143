.mr_info {
  position: relative;
  text-decoration: underline;
  z-index: 2;
}

.mr_detail {
  width: auto;
  padding: .5rem;
  position: absolute;
  z-index: 1;
  background: $body-bg;
  border: 1px solid $accent_color;
}

.manager_rate {
  position: absolute;
  left: 0;
  bottom: 0;
}