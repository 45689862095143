#quiz {
  position: relative;
  z-index: 999;
}

.quiz_nav {
  display:flex;
  justify-content: center;

  div {
    cursor: pointer;
  }
}

.quiz_rat {
  overflow: visible !important;
}

.quiz_img {
  cursor: pointer;
  transition: border, padding .3s;
  margin-bottom: 2rem;

  &:hover, &.selected {
    padding: 0;
    border: .25rem solid lighten($accent_color, 20);
  }
}

.path_steps {
  color: lighten($body-color, 40);

  li {
    display: inline-block;
    padding-right: 1rem;

    &:not(:first-child) {
      position: relative;
      padding: 0 1rem 0 2rem;

      &:before {
        content: '-*-';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .step_on {
    color: lighten($accent_color, 20);
  }
}

.ig_container{
  position:relative;
}