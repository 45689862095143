@import "components/styles/vars";
@import "components/owl/vars";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@geedmo/yamm/src/yamm";
@import "../node_modules/@fancyapps/ui/dist/fancybox.css";
@import "../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../node_modules/owl.carousel/src/scss/owl.theme.default";
@import "../node_modules/rangeslider-js/dist/styles.min.css";

@import "components/styles/fonts/fonts";
@import "components/styles/typography/styles";
@import "components/logo/styles";
@import "components/navbar/styles";
@import "components/faq/styles";
@import "components/mainpage/styles";
@import "components/right_icons/styles";
@import "components/footer/styles";
@import "components/owl/styles";
@import "components/gsap/styles";
@import "components/waves/styles";
@import "components/modal/styles";
@import "components/form/styles";
@import "components/links/styles";
@import "components/facades/styles";
@import "components/facade_system/styles";
@import "components/breadcrumbs/styles";
@import "components/doors/styles";
@import "components/news/styles";
@import "components/fancybox/styles";
@import "components/table/styles";
@import "components/decor/styles";
@import "components/video/styles";
@import "components/furniture/styles";
@import "components/carousel/styles";
@import "components/excel/styles";
@import "components/list/styles";
@import 'components/configurator/styles';
@import 'components/photoprint/styles';
@import 'components/about/styles';
@import 'components/contacts/styles';
@import 'components/managers/styles';
@import 'components/ymaps/styles';
@import 'components/search/styles';
@import "components/cookies_warning/styles";
@import 'components/quiz/styles';
@import 'components/btn_up/styles';
@import 'components/nativa/styles';

body {
  margin-top: 127px;

  @media(max-width: 991px) {
    margin-top: 0;
  }
}

.left_align {
  text-align: left !important;
}

.container-fluid {
  position: relative;
}

.columns_2 {
  columns: 2;
}

.no_break {
  break-inside: avoid-column;
}

.no-margin {
  margin: 0 !important;
}

.dark_block, .light_block {
  padding: .5rem;
}

.dark_block {
}

h2 {
  margin-bottom: 1rem;
}

p {
  padding: 0 1rem 1rem 1rem;
  text-indent: 0;
  //text-align: justify !important;
  user-select: none !important;

}

.lm_required {
  color: $accent_color !important;
}

.img_shadowed {
  box-shadow: -3px 6px 6px 0 #0004;
  transition: box-shadow 300ms;

  &:hover {
    box-shadow: none;
  }
}

.img_block {
  margin: 1rem 2rem 0 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  .h3 {
    text-align: center;
  }
}

.accent {
  color: $accent_color !important;
}

.noindent {
  text-indent: 0;
}

.dark_block_content {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: .5rem 1rem;
  }
}

.light_block {
  background: $light_block;

  p {
    margin-bottom: 0;
  }
}

.content_center {
  display: flex !important;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    img {
      max-width: 300px;
    }
  }

  img {
    display: block;
    padding: 2px;
    border: 1px solid $border-color;
    margin: 1rem;
  }

}

.toast:not(.showing):not(.show) {
  display: none;
}

.ps {
  padding: 0px 120px;
  @media (max-width: 1000px) {
    padding: 0;
  }
}

.mt-2 {
  margin-top: -2rem !important;
}

.normal_text {
  font-weight: normal !important;
  font-size: .8rem;
}

#info_toast {
  z-index: 99999;
}