.map {
  position: relative;
  margin:1rem 0;
}

.map_overlay {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
