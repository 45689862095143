.breadcrumbs {
  flex-shrink: unset;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  //font-style: italic;
  font-weight: 300;
  margin-top: -20px;
  margin-left: 250px;
  position: relative;
  z-index: 10;

  //span {
  //  white-space: nowrap;
  //}

  @media screen and (max-width: 1020px){
    margin: 1rem 2rem;
    line-height: 2;
  }
}