.logo_container {
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
  }


  @include media-breakpoint-down(md) {
    width:100%;
  }

  .navbar-text {
    font-size: .65rem;
    padding-bottom: 0;
    padding-top: 0;
    text-decoration: none;
  }
}

.logo_year {
  position: absolute;
  left: 2rem;
  top: 0;
  font-size: .7rem !important;
}