
.drug_drop_zone {
  display: flex;
  justify-content: left;
  border: 1px solid $accent_color;

  img {
    display: block;
    margin: 0 10px;
  }
}

.sf_filelist {
  font-size: .75rem;
  list-style: none;
  padding-left: 1rem;
  padding-right: 1rem;

    li{
      display: flex;
      justify-content: space-between;
      transition: background-color .5s;
      border-bottom: 1px dotted $accent_color;

      &:hover {
        background-color: darken($body-bg, 20%);
      }

      .sf_del_btn{
        padding-left:1rem;
        cursor: pointer;
        color: #77201c;
      }

    }

}