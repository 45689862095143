.nt_block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin: 2rem;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.nt_intro {
  img {
    width: 150px;
  }
}

.nt_sample {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  p {
    margin-top: .5rem;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}