footer {
  font-size: .8rem;
}

.mail_hunter {
  z-index: 1;
}

#lead_form {
  text-align: center;

  input[type="text"] {
    width:250px;
    font-size:1rem;
  }

  .wbtn {
    margin-right: 0;
  }
}

.footer_list{
  padding-left: .7rem;
}

.law{
  text-align: justify;
}

.in_develop_action {
  margin-left: -1rem;
}

.ml-1{
  margin-left: -1rem;
}