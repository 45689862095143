@import '../styles/vars';
//@import 'CSS:../../../static/cloudzoom/cloudzoom.css';

//.cloudzoom-zoom-inside {
//  z-index: 10000;
//}

.decors_container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;

  @media (max-width: 1390px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1180px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 400px) {
    grid-template-columns: auto;
  }
}

.decors_item {
  position: relative;
  margin-bottom: 2rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-end;

  .patination {
    color: #f9a602;
    border-color: #f9a602 !important;
    //background: #0008;
  }

  .aging {
    color: #000;
    border-color: #000;
    background: #fff8;
  }


}

.decor_addons {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;

  .dt_item {
    margin: 0 5px 5px 0;
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: currentcolor;
    position: relative;
    width: 24px;
    height: 24px;
    line-height: 120%;
    border-radius: 50%;
  }
}

.decor_img {
  position: relative;
}

.gloss {
  display: inline-block;
  position: absolute;
  left: -12px;
  top: -12px;

  img {
    width: 48px;
    height: 48px;
  }
}

.decor_collection_recommend {
  text-align: center;
  font-style: italic;
}

.decor_h3 {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: .5rem;
}

.decor_link {
  display: block;
  position: relative;
}

.low_price {
  width: 107px;
}


.decor_sale {
  //filter: drop-shadow(0 0 2px #000);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  transform: rotateZ(-45deg);
  font-weight: bold;
  font-size: 18px;
  color: $accent_color;
  z-index: 2;
  text-shadow: 0 0 5px #000;
}

.gloss_legend {
  width: 48px;
  height: 48px;
  position: relative;
  left: -12px;
  top: -12px;
  margin-bottom: -24px;
  margin-right: -24px;
}

