//@import "../styles/vars";

.carousel-caption, .carousel__caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 6px !important;
  padding-bottom: 1.25rem;
  color: $body-bg;
  text-align: center;
  line-height: 1;
  font-size: .8rem;
  background-color: $body-color;
}

.carousel__slide {
  //border-color: $accent_color !important;
  border: none;
  overflow: visible;

  img {
    @extend .img_shadowed;
  }
}

.carousel__nav {
  display: none !important;
}